import "./Maker.css"

function Maker(){

    return(
        <div>
            <h2 className="maker">Yudle</h2>
        </div>
    );
}

export default Maker;